import axios from 'axios';

// const BASE_URL = '/api/order';

const ordersApi = {
  getOneOrder: async (orderId) => {
    const res = await axios.get('/api/order/get-one-order', { params: { orderId }});

    return res.data || [];
  },

  getOrders: async (params) => { // params shoud contains "selectedDate: {dateFrom: ..., dateTo: ...}"
    if (Object.entries(params.selectedDate).length === 0) return; // Prevent error in TimeLine when "selectedDate" is not exists yeat on component load

    const res = await axios.get('/api/order/get-orders', {
      params
    })

    return res.data || [];
  },

  // TODO: X - Unused
  // This API neede to avoid unnecessary call "/get-orders" without propertyId
  getOrdersForOneProperty: async (params) => { // params shoud contains "selectedDate: {dateFrom: ..., dateTo: ...}"
    if (Object.entries(params.selectedDate).length === 0 || Object.entries(params.propertyId).length === 0) return; // Prevent error in TimeLine when "selectedDate" is not exists yeat on component load

    const res = await axios.get('/api/order/get-orders', {
      params
    })

    return res.data || [];
  },

  removeOrder: async (id) => {
    const res = await axios.delete('/api/order/remove-order', { data: { orderId: id } });
    
    return res.data || [];
  },

  editOrder: async (orderId, data) => {
    const res = await axios.patch('/api/order/edit-order', { orderId, data });

    return res.data || [];
  },

  saveOrder: async (data) => {
    const res = await axios.post('/api/order/save-order', data);

    return res.data || [];
  },

  removeAllOrders: async () => {
    const res = await axios.delete('/api/order/remove-all-orders');
    
    return res.data || [];
  }
}

export default ordersApi;
