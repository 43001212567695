import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useUserDataContext } from '../../../contexts/userDataProvider';
import { TextInput, Button, Switch, Textarea } from "react-materialize";
import { getRequiredInputParams } from '../../common/utils';
import settingsAPI from '../../../services/settingsAPI';
import { handleError } from '../../common/utils';
import Loader from '../../common/Loader';
import ErrorAlert from '../../common/ErrorAlert';

const PropertyItemEdit = ({
  propertyId,
  title,
  costPerNight,
  description,
  isAvailable,
  setIsEdit,
  onEditSuccess
}) => {
  const { translate } = useUserDataContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [editingItem, setEditingItem] = useState({
    title: title,
    costPerNight: costPerNight,
    description: description,
    isAvailable: isAvailable
  });

  const handleDataSet = (data) => {
    const key = Object.keys(data)[0];

    setEditingItem(prevState => (
      {
        ...prevState,
        [key]: data[key]
      }
    ))
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      setIsLoading(true);
      setError({});
      await settingsAPI.editProperty(propertyId, editingItem);
      onEditSuccess();
    } catch (err) {
      handleError(
        err.response?.status,
        err.response?.statusText,
        err.response?.data.message,
        setError
      );
    }
    finally {
      setIsLoading(false);
    }
  };

  // TODO: disable Save button if no changes

  return (
    <div className="property-item-edit">
      <Loader 
        setIsLoading={isLoading}
      />
      <ErrorAlert
        error={error}
      />
      <form onSubmit={handleSubmit}  className="property-item-edit-form"> 
          <TextInput
            value={editingItem.title}
            onChange = {(e) => handleDataSet({ title: e.target.value })}
            label={translate('title')}
            id={`itemTitle${propertyId}`}
            maxLength="30"
            {...getRequiredInputParams(translate('canNotBeEmpty'))}
          />
          <TextInput
            value={editingItem.costPerNight}
            onChange = {(e) => handleDataSet({ costPerNight: e.target.value })}
            label={translate('costNight')}
            id={`itemCostPerNight${propertyId}`}
            maxLength="8"
            type="number"
            min="0"
            max="100000"
            {...getRequiredInputParams(translate('canNotBeEmpty'))}
          />
          <Textarea
            value={editingItem.description}
            onChange = {(e) => handleDataSet({ description: e.target.value })}
            label={translate('description')}
            id="itemDescription"
            maxLength="150"
          />
          <Switch
            id={`switch-availability${propertyId}`}
            offLabel={translate('notAvailable')}
            onChange={() => handleDataSet({ isAvailable: !editingItem.isAvailable })}
            onLabel={translate('available')}
            checked={editingItem.isAvailable}
          />
          <div className="property-item-edit-buttons-wrap">
            <Button
              node="button"
              waves="light"
              type="submit"
            >
              {translate('save')}
            </Button>
            <Button
              className="cance-button"
              node="button"
              waves="light"
              style={{
                backgroundColor: '#fff',
                color: '#000'
              }}
              onClick={() => setIsEdit(false)}
            >
              {translate('cancel')}
            </Button>
          </div>
      </form>
    </div>
  )
};

PropertyItemEdit.propTypes = {
  propertyId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  costPerNight: PropTypes.number.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  setIsEdit: PropTypes.func.isRequired,
  onEditSuccess: PropTypes.func.isRequired,
  description: PropTypes.string
};

PropertyItemEdit.defaultProps = {
  description: ''
};

export default PropertyItemEdit;