import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Button } from "react-materialize";
import { MdDeleteForever } from "react-icons/md";
import DateRangePicker from '../common/DateRangePicker';
import ModalComponent from '../common/ModalComponent';
import { usePropertyContext } from '../../contexts/propertyProvider';
import { useUserDataContext } from '../../contexts/userDataProvider';
import { addOneDayToDate } from '../common/utils';
import {
  getPropertyObjByTitle,
  fitTimeInDate
} from '../common/addNewOrdersUtils';
import {
  saveEditedItem,
  handleEditOrderDataLoad,
  removeItem,
  addNewOrderRequest,
  initOrderData
} from '../common/orderRequests';
import Loader from '../common/Loader';
import ErrorAlert from '../common/ErrorAlert';
import PropertyList from './PropertyList';
import OrderDetails from'./OrderDetails';
import OrderSummary from './OrderSummary';

const initDataRange = {
  startDate: new Date(),
  endDate: null,
  key: 'selection' // key of ranges, can be different color for different key. 
};

const OrderView = ({
  timeLineNewItemData,
  closeEditModalReloadData,
  orders,
  timeLineLoadedDataRange
}) => {
  const { translate } = useUserDataContext();
  const {
    data: propertyData,
    isLoading: propertyIsLoading,
    error: propertyError
  } = usePropertyContext();

  const [isLoading, setIsLoading] = useState(false);
  const [localError, setLocalError] = useState(false);
  const [dataRange, setDataRange] = useState(initDataRange);
  const [newOrderData, setNewOrderData] = useState(initOrderData);
  const [isDateAvailable, setIsDateAvailable] = useState(true);
  const [isRemoveOrderModal, setIsRemoveOrderModal] = useState(false);

  const isOrderEdit = timeLineNewItemData.isTimeLineEditOrder;
  const isNewOrder = timeLineNewItemData.isTimeLineNewOrder;

  const handleNewOrderDataSet = (orderData) => {
    const key = Object.keys(orderData)[0];

    setNewOrderData(prevState => ({
      ...prevState,
      [key]: orderData[key]
    }));
  };

  const handleNewOrderPropertyClick = (property) => {
    // setDataRange(initDataRange); // Clear selected dates (this makes issue in DateRangePicker on changing property)
    setNewOrderData(prevState => ({ // Set Property data
      ...prevState,
      propertyId: property.propertyId,
      propertyName: property.propertyName,
      costPerNight: property.costPerNight
    }));
  };

  const handleTimeLineNewOrder = () => {
    const selectedTimeLineProperty = getPropertyObjByTitle(propertyData, timeLineNewItemData.propertyName);
    const endDate = addOneDayToDate(timeLineNewItemData.clickedDate);

    setDataRange(
      {
        startDate: fitTimeInDate(timeLineNewItemData.clickedDate),
        endDate: fitTimeInDate(endDate),
        key: 'selection'
      }
    );
    setNewOrderData(prevState => ({
      ...prevState,
      propertyId: selectedTimeLineProperty?.id,
      propertyName: timeLineNewItemData.propertyName,
      costPerNight: selectedTimeLineProperty?.costPerNight
    }));
  };

  const handleDataRangeSelection = (date) => {
    if (date) {
      let selectedDate = {...date}
      selectedDate.startDate = fitTimeInDate(date.startDate)
      selectedDate.endDate = fitTimeInDate(date.endDate)
  
      setDataRange(selectedDate);
    }
  };

  const submitEditCallback = () => {
    setNewOrderData(initOrderData); // Clear order details (this fix issue - edit than create new order -> prev data is visible) 
    closeEditModalReloadData();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isOrderEdit) {
      const editOrderData = {
        ...newOrderData,
        dateFrom: dataRange.startDate,
        dateTo: dataRange.endDate
      }

      saveEditedItem(setIsLoading, setLocalError, newOrderData.id, editOrderData, submitEditCallback);
    } 
    
    if (isNewOrder) {
      addNewOrderRequest(
        setIsLoading,
        setLocalError,
        newOrderData,
        dataRange.startDate,
        dataRange.endDate,
        setNewOrderData,
        closeEditModalReloadData
      );
    }
  };

  const isReserveButtonActive = newOrderData.firstName && newOrderData.phone && isDateAvailable;

  // Clear orderData (this fix issus - edit item, than create ne item)
  useEffect(() => {
    setNewOrderData(initOrderData);
  }, [isOrderEdit, isNewOrder]);

  useEffect(() => {
    if (isNewOrder) {
      handleTimeLineNewOrder();
    } 
    if (isOrderEdit) {
      handleEditOrderDataLoad(setIsLoading, setLocalError, setDataRange, setNewOrderData, timeLineNewItemData.orderId);
    } 
  }, [timeLineNewItemData, propertyData]);

  const removeOrderCallback = () => {
    setIsRemoveOrderModal(false);
    closeEditModalReloadData();
  };

  return (
    <div className="order-view-wrap">
      <Loader 
        setIsLoading={isLoading || propertyIsLoading}
      />
      <ErrorAlert
        error={localError || propertyError}
      />
      <ModalComponent 
        ModalContent={translate('thisReservationWillBeRemoved')}
        onSubmitButtonHandler={() => removeItem(
          setIsLoading,
          setLocalError,
          timeLineNewItemData.orderId,
          removeOrderCallback
        )}
        onSubmitButtonName={translate('yesRemove')}
        show={isRemoveOrderModal}
        handleClose={() => setIsRemoveOrderModal(false)}
        type="WARNING"
      />
      <div>{isOrderEdit ? translate('editOrder') : translate('addNewOrder')}</div>
      <div className="flex-container">
        <div className="item-first">
          <DateRangePicker
            setDataRange={handleDataRangeSelection}
            translate={translate}
            dataRange={dataRange}
            isComponentDisabled={!newOrderData.propertyId}
            propertyId={newOrderData.propertyId}
            orders={orders}
            timeLineLoadedDataRange={timeLineLoadedDataRange}
            isOrderEdit={isOrderEdit}
            currentlyEditedOrderData={newOrderData}
            setIsDateAvailable={setIsDateAvailable}
            isDateAvailable={isDateAvailable}
          />
        </div>
        <div className="item-second">
          <PropertyList
            setData={handleNewOrderPropertyClick}
            timeLineSelectedElementId={newOrderData.propertyId}
          />
        </div>
        <div className="item-third">
        <OrderDetails
          setData={handleNewOrderDataSet}
          newOrderData={newOrderData}
        />
        </div>
      </div>
      <OrderSummary
        setData={handleNewOrderDataSet}
        newOrderData={newOrderData}
        dataRange={dataRange}
        isOrderEdit={isOrderEdit}
      />
      <div className="buttons-wrap">
        <Button
          className="submit-button"
          node="button"
          disabled={!isReserveButtonActive}
          onClick={handleSubmit}
        >
          {translate('save')}
        </Button>
        {isOrderEdit ? 
          <MdDeleteForever
            className="remove-icons"
            size={'3.5em'}
            title={translate('remove')}
            onClick={() => setIsRemoveOrderModal(true)}
          />
          : null
        }
      </div>
    </div>
  );
};

OrderView.propTypes = {
  timeLineNewItemData: PropTypes.shape({
    isTimeLineNewOrder: PropTypes.bool,
    isTimeLineEditOrder: PropTypes.bool,
    orderId: PropTypes.string,
    propertyName: PropTypes.string,
    clickedDate: PropTypes.instanceOf(Date)
  }),
  closeEditModalReloadData: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({})
  ),
  timeLineLoadedDataRange: PropTypes.shape({
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date)
  }).isRequired
};

OrderView.defaultProps = {
  timeLineNewItemData: {
    isTimeLineNewOrder: false,
    isTimeLineEditOrder: false,
    orderId: undefined,
    propertyName: undefined,
    clickedDate: undefined
  }
};

export default OrderView;
