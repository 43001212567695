import React, { useState, useEffect, useRef } from "react";
import { useUserDataContext } from '../../contexts/userDataProvider';
import { usePropertyContext } from '../../contexts/propertyProvider';
import { GrMenu } from 'react-icons/gr';
// import { createRoot } from 'react-dom/client';
import { options } from './options';
// import ItemTemplate from './ItemTemplate';
import ordersAPI from '../../services/ordersAPI';
import { useGetOrders } from '../common/fetchData.hooks';
import Timeline from 'react-visjs-timeline';
import M from 'materialize-css';
import { defaultLanguage, getObjectByValue } from '../common/utils';
import {
  parseGroupForTimeline,
  parseOrdersForTimeline,
  focusTimeline,
  addMonthToDate,
  getObjectById,
  isDateChanged,
  isDaysAmountChanged,
  findProperyIdByName
} from './timeLineUtils';
import { saveEditedItem } from '../common/orderRequests';
import { fitTimeInDate } from '../common/addNewOrdersUtils';
import TimelineFilters from './TimelineFilters';
import Loader from '../common/Loader';
import ErrorAlert from '../common/ErrorAlert';
import ModalComponent from '../common/ModalComponent';
import OrderView from "./OrderView";

const TimeLineView = () => {
  const [groupForTimeline, setGroupForTimeline] = useState([]);
  const [ordersForTimeLine, setOrdersForTimeLine] = useState([]);
  const [selectedDate, setSelectedDate] = useState({}); 
  const [isModal, setIsModal] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [editError, setEditError] = useState(false);
  const [itemData, seItemData] = useState({
    isTimeLineEditOrder: false,
    isTimeLineNewOrder: false,
    propertyName: undefined,
    clickedDate: undefined
  });
  const [warning, setWarning] = useState(null);

  const timelineRef = useRef(null);

  const {
    data: propertyData, // TimeLine groups can be not uppdated because can't detect changes inside [] (e.g. on title change or remove). Try to use native groupDelete method 
    isLoading: isPropertyLoading,
    error: errorProperty,
  } = usePropertyContext([]);

  const {
    translate,
    data: userData,
    isLoading: isUserDataLoading,
    error: userDataError,
  } = useUserDataContext();

  const {
    data: orders,
    isLoading: isOrderLoading,
    error: errorOrder,
    reloadData: reloadOrderData
  } = useGetOrders(
    ordersAPI.getOrders,
    {selectedDate: selectedDate}
  );

  const clickHandler = (props) => {
    // console.log("--Click--", props)
  };


  const rangeChangeHandler = (props) => {
    // console.log("--Range--", props)
  };

  const selectHandler = (props) => {
    // console.log("--props--", props)
  };

  const submitEditCallback = () => {
    // setNewOrderData(initOrderData);
    // closeEditModalReloadData();
    reloadOrderData();
  };

  // Display the popup warning
  const displayToast = (message) => {
    M.toast({ html: message, displayLength: 5000 });
  };

  const onMoveHandler = ({ id, start, end, group }) => {
    const orderBeforeEdit = getObjectById(orders, id);

    const originDates = {
      start: orderBeforeEdit.dateFrom,
      end: orderBeforeEdit.dateTo
    };

    const changedDates = {
      start: start,
      end: end
    };

    const isMoovedToAnotherDay = isDateChanged(originDates, changedDates);
    const isPropertyChanged = group !== orderBeforeEdit.propertyName;

    if (!isMoovedToAnotherDay && !isPropertyChanged) return;

    let newPropertyId;
    let newCostPerNight;

    if (isPropertyChanged) {
      const newProperty = findProperyIdByName(propertyData, group)
      newPropertyId = newProperty.id;
      newCostPerNight = newProperty.costPerNight;
    };

    // Show warning on group change
    if (newCostPerNight && newCostPerNight !== orderBeforeEdit.costPerNight) {
      setWarning(translate('costPerNightChanged'));
    }

    // Show warning on days amount change
    if (isDaysAmountChanged(originDates, changedDates)) {
      setWarning(translate('daysAmontChanged'));
    }

    const editOrderData = {
      costPerNight: newCostPerNight,
      dateFrom: fitTimeInDate(start),
      dateTo: fitTimeInDate(end),
      propertyId: newPropertyId, // if not mooved in another group, than "newPropertyId" will be undefined and not saved by request
      propertyName: group,
      totalCost: orderBeforeEdit.totalCost
    };

    saveEditedItem(setIsEditLoading, setEditError, id, editOrderData, submitEditCallback);
  };

  const openModalClickHandler = (props) => {
    const isEdit = props.what === 'item';  // Item click
    const isEmptyArea = props.what === 'background'; // TimeLine empty area click

    // if(!props.event.hasOwnProperty('isTrusted')){ // If click NOT on TimeLine (e.g. to close modal)

    if (isEdit) {
      seItemData({
        isTimeLineEditOrder: true,
        propertyName: props.group,
        orderId: props.item
      });

      setIsModal(true);
    };

    if (isEmptyArea) {
      // if (new Date() > props.time) { // Do not show modal on past date click
      //   return
      // }

      // Do not open modal if Group is Disabled in Settings
      const selectedGroupData = getObjectByValue(propertyData, 'title', props.group);
      console.log("--isAvailable--", selectedGroupData.isAvailable)
      if (!selectedGroupData.isAvailable) {
        return;
      };

      seItemData({
        isTimeLineNewOrder: true,
        propertyName: props.group,
        clickedDate: props.time
      })
      setIsModal(true)
    }
  };

  // let root = null;
  const buildOptions = {
    ...options,
    min:  selectedDate.dateFrom || new Date(),  // lower limit of visible range. "||" - to avoid error on init when "selectedDate.dateFrom" is undefined
    max: addMonthToDate(selectedDate.dateFrom, 1) || new Date(),     // upper limit of visible range
    locale: userData[0].selectedLocale || defaultLanguage,
    onMove: (item) => {
      onMoveHandler(item);
    }
    // template: function (item, element) {
    //   if (!item) {
    //     return;
    //   }
  
    //   if (!root) {
    //     root = createRoot(element);
    //   }
  
    //   root.render(<ItemTemplate item={item} />);
    //   return '';
    // }
  };

  const closeEditModalReloadData = () => {
    setIsModal(false);
    reloadOrderData();
  };

  const timeline = timelineRef.current?.$el;
  
  useEffect(() => {
    const fitTimelineContent = () => {
      if (!!orders.length) {
        timeline.fit();
      }
    };

    if (propertyData?.length && orders) { // orders can be undefined
      setOrdersForTimeLine(parseOrdersForTimeline(orders));
      setGroupForTimeline(parseGroupForTimeline(propertyData));
      fitTimelineContent(); // TODO: This doesn't work
    }
  }, [orders, propertyData?.length, propertyData]);

  useEffect(() => {
    reloadOrderData();
    focusTimeline(timeline, selectedDate.dateFrom);
  }, [selectedDate, timeline]); // Can't add "reloadOrderData" because of infinity reloading

  useEffect(() => {
    if (warning) {
      displayToast(warning);
      setWarning(null);
    }
  }, [warning]);

  if (!propertyData?.length) {
    return (
      <div className="timeline-noGroupExists-wrap">
        <p>{translate('addFirstPropertyInSettings')}</p>
        <GrMenu className="menu-button" />
      </div>
    )
  };

  return (
    <div className="timeline-wrap">
      <Loader 
        setIsLoading={isOrderLoading || isPropertyLoading || isUserDataLoading || isEditLoading}
      />
      <ErrorAlert
        error={errorOrder || errorProperty || userDataError || editError}
      />
      <ModalComponent 
        ModalContent={
          <OrderView
            timeLineNewItemData={itemData}
            closeEditModalReloadData={closeEditModalReloadData}
            orders={orders}
            timeLineLoadedDataRange = {selectedDate}
          />
        }
        hideCancelButton
        show={isModal}
        handleClose={() => setIsModal(false)}
      />
      <TimelineFilters
        setSelectedDate={setSelectedDate} // Set 2 month range
        timelineRef={timeline}
      />
      <Timeline
        ref={timelineRef}
        options={buildOptions}
        items={ordersForTimeLine}
        groups={groupForTimeline}
        // clickHandler={clickHandler}
        // rangechangeHandler={rangeChangeHandler}
        // selectHandler={selectHandler}
        // doubleClickHandler={openModalClickHandler}
        clickHandler={openModalClickHandler}
      />
    </div>
  );
};

export default TimeLineView;
