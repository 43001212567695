import React, { createContext, useContext } from 'react';
import { useFetch } from '../components/common/fetchData.hooks';
import coworkerAPI from '../services/coworkerAPI';
import { coworkerRequestStatus } from '../components/common/utils';

const CoworkerRequestContext = createContext();

export const CoworkerRequestProvider = ({ children }) => {
  const { data, isLoading, error, reloadData } = useFetch(coworkerAPI.getCoworkerRequests);

  // The invitation is approwed, Coworker is active
  let isApprovedCoworker = null;

  if (data) {
    isApprovedCoworker = data[0]?.status === coworkerRequestStatus.approved
  };

  const workSpaceData = data?.filter(item => item.status === coworkerRequestStatus.approved)[0];
  const workSpaceEmail = workSpaceData?.requestedBy;
  const workSpaceId = workSpaceData?.id;

  return (
    <CoworkerRequestContext.Provider value={{
      data,
      isLoading,
      error,
      reloadData,
      isApprovedCoworker,
      workSpaceEmail,
      workSpaceId
    }}>
      {children}
    </CoworkerRequestContext.Provider>
  );
};

export const useCoworkerRequestContext = () => {
  return useContext(CoworkerRequestContext);
};
